import React from 'react';
import { MapContainer, TileLayer, LayersControl, FeatureGroup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import MapMarkers from './databaserender';

const { BaseLayer, Overlay } = LayersControl;

const MapComponent = ({showMarker1}) => {
    return (
        <MapContainer 
            style={{ height: "100%", width: "100%", zIndex: "30" }} // Adjusted height and width
            center={[13, 100]}
            zoom={6}
            zoomControl={false}
        >
            <LayersControl position="topright">
                {/* Base Layers */}
                <BaseLayer name="OpenStreetMap" checked>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                </BaseLayer>
                <BaseLayer name="Alidade Satellite">
                    <TileLayer
                        url="https://tiles.stadiamaps.com/tiles/alidade_satellite/{z}/{x}/{y}.png"
                        attribution='&copy; CNES, Distribution Airbus DS, © Airbus DS, © PlanetObserver (Contains Copernicus Data) | &copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                </BaseLayer>

                {/* Overlay Layers */}
                <Overlay name="Festival Locations" checked>
                    <FeatureGroup pathOptions={{ color: 'purple' }}>
                        
                        {showMarker1 && <MapMarkers />}
                    </FeatureGroup>
                </Overlay>
            </LayersControl>
        </MapContainer>
    );
}

export default MapComponent;
